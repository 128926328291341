import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDay,
  faEnvelope,
  faMapLocation,
  faPeopleGroup,
  faPhone,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import usePreIntakeHook from '../../../hooks/modules/pre-intake/usePreIntakeHook';
import { toUcFirst } from '../../../helpers/utils';
import PhoenixLoader from '../../../components/common/PhoenixLoader';
import { genders } from '../../../data';
import { TPreIntake, TPreIntakeValidation } from '../../../types/intake';
import usePreIntakeValidationHook from '../../../hooks/modules/pre-intake/usePreIntakeValidationHook';
import { toast } from 'react-toastify';
import PreIntakeHistory from './history';
import usePreIntakeHistoryHook from '../../../hooks/modules/pre-intake/usePreIntakeHistoryHook';
import PreIntakeValidationForm from '../../../components/modules/pre-intake/forms/PreIntakeValidationForm';
import { checkScope } from '../../../helpers/auth';
import { useTranslation } from 'react-i18next';
import PreIntakeForm from '../../../components/modules/pre-intake/forms/PreIntakeForm';
import PreIntakeDetailInValidation from './detail';

const PreIntakeValidation = () => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!checkScope('pre-intake-update')) navigate('/error/403');
  }, []);
  const { id } = useParams();
  const [preIntakeId, setPreIntakeId] = useState<number | undefined>(undefined);

  const { fetchOnePreIntake, updatePreIntake } = usePreIntakeHook();
  const [preIntake, setPreIntake] = useState<TPreIntake | null>(null);

  const { createPreIntakeValidation } = usePreIntakeValidationHook();
  const { fetchAllPreIntakeHistory, preIntakeHistories } =
    usePreIntakeHistoryHook();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (id) {
      setPreIntakeId(parseInt(id));
    }
  }, [id]);

  useEffect(() => {
    if (preIntakeId) {
      fetchOne();
      fetchHistory();
    }
  }, [preIntakeId]);
  const fetchOne = () => {
    setLoading(true);
    fetchOnePreIntake(preIntakeId as number)
      .then(row => setPreIntake(row))
      .catch(e => console.log(e))
      .finally(() => setLoading(false));
  };
  const fetchHistory = () => {
    setLoading(true);
    fetchAllPreIntakeHistory(preIntakeId as number)
      .catch(e => console.log(e))
      .finally(() => setLoading(false));
  };

  const handleOnSubmit = (values: TPreIntakeValidation) => {
    createPreIntakeValidation(preIntake?.id as number, values)
      .then(() => {
        toast.success(
          `${toUcFirst(values.decision as string)} (${preIntake?.pre_intake_case_id})`
        );
        if (values.decision === 'save_to_history') {
          fetchHistory();
        } else {
          // navigate('/pre-intake');
          fetchOne();
        }
      })
      .catch(e => {
        console.log(e);
        toast.error(e?.data?.message || 'Something Went Wrong');
      });
  };
  const handleOnPreIntakeSubmit = (formData: TPreIntake) => {
    setLoading(true);
    if (preIntake) {
      updatePreIntake(preIntake.id as number, formData)
        .then(row => {
          setPreIntake(row);
          toast.success(`Save Pre Intake.`);
        })
        .catch(e => {
          toast.error(e?.data?.message || 'Something Went Wrong');
        })
        .finally(() => setLoading(false));
    }
  };
  return (
    <>
      <Row>
        <Col md={3}>
          <Stack gap={5} className="mb-5">
            <Card className="border-0 shadow bg-gradient--blue rounded-3">
              <Card.Body>
                {!loading ? (
                  <>
                    <div className="d-flex  align-items-center justify-content-between mb-2">
                      <h3 className="text-white fw-bolder mb-0">
                        {preIntake?.individual_full_name}
                      </h3>
                      <div className="d-flex align-items-center gap-3"></div>
                    </div>{' '}
                    <Stack gap={2}>
                      <Row className="g-1">
                        <Col md={12}>
                          <div>
                            <FontAwesomeIcon
                              icon={faUser}
                              className="me-2 text-body-quaternary fs-9"
                            />
                            <span className="text-white">
                              {preIntake?.individual_gender
                                ? genders.filter(
                                    data =>
                                      data.value == preIntake.individual_gender
                                  )?.[0]?.label
                                : 'N/A'}
                            </span>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div>
                            <FontAwesomeIcon
                              icon={faPhone}
                              className="me-2 text-body-quaternary fs-9"
                            />
                            <span className="text-white">
                              {preIntake?.individual_phone_number || 'N/A'}
                            </span>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div>
                            <FontAwesomeIcon
                              icon={faEnvelope}
                              className="me-2 text-body-quaternary fs-9"
                            />
                            <span className="text-white">
                              {' '}
                              {preIntake?.individual_email || 'N/A'}
                            </span>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div>
                            <FontAwesomeIcon
                              icon={faPeopleGroup}
                              className="me-2 text-body-quaternary fs-9"
                            />
                            <span className="text-white">
                              {' '}
                              {preIntake?.individual_age_range
                                ? toUcFirst(preIntake.individual_age_range)
                                : 'N/A'}
                            </span>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div>
                            <FontAwesomeIcon
                              icon={faMapLocation}
                              className="me-2 text-body-quaternary fs-9"
                            />
                            <span className="text-white">
                              {preIntake?.individual_address || 'N/A'}
                            </span>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div>
                            <FontAwesomeIcon
                              icon={faCalendarDay}
                              className="me-2 text-body-quaternary fs-9"
                            />
                            <span className="text-white">
                              {' '}
                              {preIntake?.pre_intake_date || 'N/A'}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </Stack>
                  </>
                ) : (
                  <PhoenixLoader />
                )}
              </Card.Body>
            </Card>
            <Row>
              <Col md={12}>
                <PreIntakeHistory
                  preIntakeHistories={preIntakeHistories}
                  loading={loading}
                />
              </Col>
            </Row>
          </Stack>
        </Col>
        <Col md={9}>
          <Stack gap={5}>
            <Card className="border-0 shadow rounded-3">
              <Card.Body>
                {preIntake?.status != 'validated' ? (
                  <PreIntakeForm
                    loading={loading}
                    preIntake={preIntake as TPreIntake}
                    onSubmit={handleOnPreIntakeSubmit}
                  />
                ) : (
                  <PreIntakeDetailInValidation
                    preIntake={preIntake}
                    loading={loading}
                  />
                )}
              </Card.Body>
            </Card>

            {preIntake?.status && (
              <Card className="border-0 shadow rounded-3">
                <Card.Body>
                  <h6 className="mb-4">{t('validators_decision')}?</h6>
                  {preIntake?.status == 'validated' ||
                  preIntake?.status == 'rejected' ? (
                    <>
                      <div className="mb-3">
                        <Row>
                          <Col md={4}>
                            <h6> {t('findings')}</h6>
                            <span>
                              {preIntake?.validation_details?.findings}
                            </span>
                          </Col>
                          <Col md={4}>
                            <h6>{t('conclusion')}</h6>
                            <span>
                              {preIntake?.validation_details?.conclusion}
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : (
                    <Card className="border-0 shadow rounded-3">
                      <Card.Body>
                        <PreIntakeValidationForm
                          onSubmit={handleOnSubmit}
                          loading={loading}
                          preIntake={preIntake as TPreIntake}
                        />
                      </Card.Body>
                    </Card>
                  )}
                </Card.Body>
              </Card>
            )}
          </Stack>
        </Col>
      </Row>
    </>
  );
};

export default PreIntakeValidation;
