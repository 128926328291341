import React, {useMemo} from 'react';
import support from 'assets/img/support.svg';
import support_svg from 'assets/img/mask.svg';
import track2 from 'assets/img/track-2.png';
import {Col, Container, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAt, faChevronRight, faPhone} from '@fortawesome/free-solid-svg-icons';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import StatusBadge from '../../components/common/StatusBadge';

const TrackResults = () => {
    // eslint-disable-next-line
    const {t}: { t: any } = useTranslation();
    const location = useLocation() || null
    const trackCase = useMemo(() => {
        console.log(location?.state?.trackCase)
        return location?.state?.trackCase
    }, [location?.state?.trackCase])
    return (
        <div className='d-flex align-items-center h-100'><Container>
            <Row className='d-flex gap-4 gap-lg-0 justify-content-center'>
                <Col xs={12} md={12} lg={4}>
                    <div className='d-flex align-items-start gap-3 bg-white p-5 rounded-3 support-block pb-7' style={{backgroundImage: `url(${support_svg})`}}>
                        <img src={support} alt="support" width='48'/>
                        <div className=''>
                            <h4 className='mb-3'>{t('support')}</h4>
                            <p className='mb-3'>{t('call_for_assistance')}</p>
                            <div className='d-flex flex-column gap-2'>
                                <div className='d-flex align-items-center'>
                                    <FontAwesomeIcon icon={faPhone} className='me-2 text-primary fs-9'/><span className='fw-bold'>+855 012 791 867</span>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <FontAwesomeIcon icon={faWhatsapp} className='me-2 text-success fs-9'/><span className='fw-bold'>+855 012 791 867</span>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <FontAwesomeIcon icon={faAt} className='me-2 text-primary fs-9'/><span className='fw-bold'>isscms@live.com</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={12} lg={6}>
                    <div className="bg-white text-center rounded-3 d-flex flex-column gap-2 p-5">
                        <img src={track2} alt="track" className="w-50 m-auto"/>
                        <div className="fs-7 mt-3">
                            {t('tracking_pre_case_id')}: <span className="fw-bold fs-7">{trackCase?.pre_intake_case_id}</span></div>
                        <p className='mb-1'>
                            {t('details_about_your_case')}
                        </p>
                        {
                            trackCase?.current_stage && (
                                <>
                                    <p className='mb-1'>{t('current_case_status')}:</p>
                                    <h5>{trackCase?.current_stage}</h5>
                                </>
                            )
                        }

                        <div>
                            <StatusBadge status={trackCase?.pre_intake_status as string}/>
                        </div>
                    </div>
                    <Link to="/track-my-case" className='btn btn-outline-warning rounded-pill w-100 mb-4 mt-4'>
                        {t('track_another_case')}
                        <FontAwesomeIcon icon={faChevronRight} className="ps-2"/>
                    </Link>
                </Col>
            </Row>
        </Container>
        </div>
    );
};

export default TrackResults;
